






























import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import PreviewRow from '~/components/blog/preview-row'
import PixabayImage from '~/components/blog/pixabay-image'

@Component({
    components: {}
})
export default class PixabaySearch extends Vue {
    @Prop({
        default: () => {
            return ''
        }
    })
    value!: string

    keyword = ''
    notFoundStr = ''
    result: PreviewRow[] = []

    previewTotal = 0
    loaded = 0
    loading = false

    currentPage = 1
    perPage = 15
    totalCount = 0
    totalPages = 0

    resize(stage: number) {
        const div = this.$refs.preview as HTMLDivElement
        if (stage === 0) {
            div.style.height = '700px'
        }
        if (stage === 1) {
            div.style.height = '550px'
        }
        if (stage === 2) {
            div.style.height = '400px'
        }
    }

    @Emit()
    input(value: string) {
        //
    }

    get localValue(): string {
        return this.value
    }

    set localValue(value: string) {
        this.input(value)
    }
    async toPage(pageNumber: number) {
        this.currentPage = pageNumber
        this.search()
    }
    async search(reset = false) {
        if (this.keyword.length > 100) {
            alert('kerword(s) cannot be over 100 characters')
        }
        if (reset) this.currentPage = 1
        const query = {
            key: '11075228-333eeacbb2da05dc7ca77fa72',
            q: this.keyword,
            page: this.currentPage + '',
            per_page: this.perPage + ''
        }

        this.loading = true
        const res = await createRequest('/blog/pixabay', 'GET', query).send()
        this.totalCount = res.data.totalHits
        this.totalPages = Math.ceil(res.data.totalHits / this.perPage)

        if(this.totalCount === 0) {
            this.notFoundStr = 'No result for ' + this.keyword
            this.loading = false
            return
        }

        const imgs: PixabayImage[] = res.data.hits
        this.previewTotal = imgs!! ? imgs.length : 0
        this.loaded = 0

        const maxW = document.getElementById('preview-area')!!.offsetWidth
        this.result = []
        for (const img of imgs) {
            let i
            for (i = 0; i < this.result.length; i++) {
                if (this.result[i].tryInsert(img)) {
                    break
                }
            }
            if (i >= this.result.length) {
                const newRow = new PreviewRow(maxW)
                this.result.push(newRow)
                newRow.tryInsert(img)
            }
        }
    }

    imageLoaded() {
        this.loaded++
        if (this.loaded >= this.previewTotal)
            this.loading = false
    }
}
