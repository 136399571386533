import PixabayImage from '~/components/blog/pixabay-image'

function ratio(img: PixabayImage) {
    return img.previewWidth / img.previewHeight
}

export default class PreviewRow {
    maxW = 0
    remainW = 0
    images: PixabayImage[] = []
    height = 100
    finalHeight = 0

    constructor(width) {
        this.maxW = width
        this.remainW = width
    }

    tryInsert(img: PixabayImage) {
        const r = ratio(img)
        const w = r * this.height + 10

        if (w < this.remainW) {
            this.remainW -= w
            this.images.push(img)
            return true
        }
        return false
    }
    
    getFinalHeight() {
        if(this.finalHeight > 0) {
            return this.finalHeight
        }
        const usableW = this.maxW - this.images.length * 4
        const totalUnit = this.images.reduce((t, img)=>{return t + ratio(img)}, 0)
        this.finalHeight = Math.floor(usableW / totalUnit)
        if(this.finalHeight > 150)
            this.finalHeight = 100
        return this.finalHeight
    }

    getFinalWidth(img) {
        const r = ratio(img)
        return r * this.finalHeight
    }
}
