






















import {Component, Emit, Prop, Ref, Vue} from 'vue-property-decorator'
import {NewBlogPost} from '~/components/data-class/data-class'
import Ckeditor from '~/components/ckeditor.vue'
import ContentEditor from '~/components/app/content-editor.vue'

@Component({
    components: {
        Ckeditor,
        ContentEditor
    }
})
export default class BlogContent extends Vue {
    @Ref() contentEditor: ContentEditor

    @Prop({
        default: () => {
            return new NewBlogPost()
        }
    })
    value!: NewBlogPost

    secretClick: number = 0

    showSecret: boolean = false

    get localValue(): NewBlogPost {
        return this.value
    }

    set localValue(value: NewBlogPost) {
        this.input(value)
    }

    @Emit()
    input(value: NewBlogPost) {
        //
    }

    updateSecretClick() {
        this.secretClick += 1
        this.showSecret = this.secretClick >= 10
    }
}

