























































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'
import {NewBlogPost, UploadImage} from '~/components/data-class/data-class'
import PixabaySearch from '~/components/blog/pixabay-search.vue'
import ImageCropper from '~/views/blog/image-cropper.vue'

@Component({
    components: {
        PixabaySearch,
        ImageCropper
    }
})
export default class BlogContent extends Vue {
    @Prop({
        default: () => {
            return new NewBlogPost()
        }
    })
    value!: NewBlogPost
    rawImgPath = ''
    croppingStep = 0

    mounted() {
        const cropper = this.$refs.cropper as ImageCropper
        cropper.setOutputSize(480, 270)
    }

    contentUploadImage(uploadedImage: UploadImage) {
        this.value.images.push(uploadedImage)
    }

    uploadImg(e: Event) {
        e.preventDefault()
        if (!e.target) return
        if (!e.target['files']) return

        const files = e.target['files']
        this.rawImgPath = URL.createObjectURL(files[0])
    }

    dataURLtoFile(dataurl: string, filename: string): File | void {
        const arr = dataurl.split(',')
        const matches = arr[0].match(/:(.*?);/)
        if (matches) {
            const mime = matches[1]
            const bstr = atob(arr[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], filename, {type: mime})
        } else return
    }

    crop() {
        const cropper = this.$refs.cropper as ImageCropper
        if (this.croppingStep === 0) {
            this.value.thumbnailURL = cropper.crop()
            this.value.new_thumbnail = this.dataURLtoFile(this.value.thumbnailURL, 'thumbnail.jpg')
            cropper.setOutputSize(180, 180)
            this.croppingStep = 1
        } else {
            this.value.thumbnailSquareURL = cropper.crop()
            this.value.new_thumbnail_square = this.dataURLtoFile(this.value.thumbnailSquareURL, 'thumbnailSquare.jpg')
            cropper.setOutputSize(480, 270)
            this.croppingStep = 0
        }
    }

    updateCropView() {
        const cropper = this.$refs.cropper as ImageCropper
        Vue.nextTick(() => {
            cropper.draw()
        })
    }

    @Emit()
    input(value: NewBlogPost) {
        //
    }

    get localValue(): NewBlogPost {
        return this.value
    }

    set localValue(value: NewBlogPost) {
        this.input(value)
    }

    get thumbnailURL() {
        if(this.value.thumbnailURL.startsWith('data'))
            return this.value.thumbnailURL
        return this.value.thumbnailURL + '-rj?v=' + this.value.thumbnail.version
    }

    get thumbnailSquareURL() {
        if(this.value.thumbnailSquareURL.startsWith('data'))
            return this.value.thumbnailSquareURL
        return this.value.thumbnailSquareURL + '-rj?v=' + this.value.thumbnail_square.version
    }
}

