



































































import {Component, Vue} from 'vue-property-decorator'
import {BlogPost, BlogPostStatus, NewBlogPost} from '~/components/data-class/data-class'
import BlogInfo from './blog-info.vue'
import BlogContent from './blog-content.vue'
import BlogThumbnail from './blog-thumbnail.vue'
import {createRequest} from '~/utils/network-request'
import {toInstanceForce} from '~/utils/Serializer'
import {BlogPermission} from '~/utils/permissions'
import copy from 'copy-to-clipboard'
import generateHTMLFromBlocks from '~/utils/json-blocks-to-html'
import {webDomain} from '~/utils/misc'
import {BlogPostAdminEdit, BlogPostEditable} from "@afterschool.dev/as-data-admin";

function containsSpecialCharacters(str): boolean {
    // let format = /[\s\t~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
    const format = /[\s\t~`!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/
    return format.test(str)
}

function pad(n: number, width: number, c: string = '0'): string {
    const nStr = n + ''
    return nStr.length >= width ? nStr : new Array(width - nStr.length + 1).join(c) + nStr
}

@Component({
    components: {
        BlogThumbnail,
        BlogInfo,
        BlogContent
    },
    metaInfo() {
        return {
            title: 'Blog Post'
        }
    }
})
export default class EditBlog extends Vue {
    static permission = BlogPermission.EditBlog

    newBlog = new NewBlogPost()
    blog = new BlogPost()
    loading = false
    imageCount = 0

    BlogPostStatus = BlogPostStatus
    statusOptions: Array<{ value: number, label: string }> = [
        {
            value: BlogPostStatus.PENDING,
            label: 'Pending'
        },
        {
            value: BlogPostStatus.PUBLISHED,
            label: 'Published'
        },
        {
            value: BlogPostStatus.HIDDEN,
            label: 'Hidden'
        }
    ]

    selectClass = {
        [BlogPostStatus.PENDING]: 'select-pending',
        [BlogPostStatus.PUBLISHED]: 'select-published',
        [BlogPostStatus.HIDDEN]: 'select-hidden',
    }

    activeIndex = '0'

    get previewURL() {
        const path = `${this.$route.params['id'].replace(/BPID-0*/, '')}/${this.blog.url_title}/`
        return `${webDomain()}/blog-post/${path}`
    }

    handleNavMenuChange(key, keyPath) {
        this.activeIndex = key
        switch (key) {
            case '0':
                this.$router.replace({query: {tab: 'info'}})
                break
            case '1':
                this.$router.replace({query: {tab: 'content'}})
                break
            case '2':
                this.$router.replace({query: {tab: 'thumbnail'}})
                break
        }
    }

    async created() {
        //  fetch blog data first before switch tab to prevent editor not reactive issue
        if (this.$route.params['id'])
            await this.getBlog()
        else {
            //  set new_editor = true for new blog
            this.newBlog.new_editor = true
        }
        if (this.$route.query['tab'] === 'content')
            this.activeIndex = '1'
        if (this.$route.query['tab'] === 'thumbnail')
            this.activeIndex = '2'
    }

    async getBlog() {
        // Edit
        this.blog._id = this.$route.params['id']

        const res = await createRequest(`/blog/blog-post/${this.$route.params['id']}`, 'get').send()
        this.blog = toInstanceForce(new BlogPost(), res.data.blog_post)
        this.newBlog = toInstanceForce(new NewBlogPost(), res.data.blog_post)
        this.newBlog.tags = [...this.blog.tags]

        // this.ready = true

        this.blog.content.split('<img ').forEach((str) => {
            const regex = /src='.*img\d{3}/g
            const matches = str.match(regex)
            if (matches) {
                const match = matches[0]
                const index = Number(match.substring(match.length - 3, match.length))
                if (index >= this.imageCount) this.imageCount = index + 1
            }
        })
    }

    async onSubmit() {
        if (containsSpecialCharacters(this.newBlog.url_title)) {
            alert('URL SLUG have special characters')
            return
        }

        if (this.$route.params['id']) {
            const isNewBlogJsonEmpty = !(this.newBlog.content_json['blocks'] && this.newBlog.content_json['blocks'].length)
            if (!this.newBlog.new_editor && !isNewBlogJsonEmpty) {
                try {
                    await this.$confirm('New editor content is not empty, do you want to overwrite old editor content and generate HTML?', 'Save Edited Changes', {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        type: 'warning'
                    })
                    this.newBlog.new_editor = true
                }
                catch (e) {
                }
            }
            if (this.newBlog.new_editor)
                this.newBlog.content = await generateHTMLFromBlocks(this.newBlog.content_json['blocks'])
            await this.editBlog()
        }
        else {
            this.newBlog.content = await generateHTMLFromBlocks(this.newBlog.content_json['blocks'])
            await this.createBlog()
        }
    }

    async uploadThumbnails({blogId, thumbnail, thumbnailSq}: {blogId: string, thumbnail?: any, thumbnailSq?: any}) {
        const formData = new FormData()
        if (thumbnail)
            formData.append('thumbnail', thumbnail)
        if (thumbnailSq)
            formData.append('thumbnailSquare', thumbnailSq)
        await createRequest(`/blog/blog-post/${blogId}/thumbnail`, 'POST', {}, formData).send()
    }

    async createBlog() {
        this.newBlog.content = '<div style=\'word-wrap: break-word\'>' + this.newBlog.content + '</div>'

        type KeysMatching<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];
        const trimFields: (KeysMatching<NewBlogPost, string>)[] = ["title", "meta_description", "url_title"]
        for (const f of trimFields) {
            this.newBlog[f] = this.newBlog[f].trim()
        }

        const body = BlogPostAdminEdit.createUpdateObj(this.newBlog)
        if (body.publish_on === 0)
            delete body.publish_on
        delete body.thumbnailURL
        delete body.thumbnailSquareURL

        this.loading = true
        try {
            const res = await createRequest('/blog/blog-post', 'POST', {}, body).send()
            const {
                blog_post_id: blogId
            }: {
                blog_post_id: string
            } = res.data
            if (this.newBlog.new_thumbnail || this.newBlog.new_thumbnail_square)
                await this.uploadThumbnails({blogId, thumbnail: this.newBlog.new_thumbnail, thumbnailSq: this.newBlog.new_thumbnail_square})

            await this.$router.push(`/blog/edit/${blogId}`)
            this.$message({
                message: 'Success, Blog saved.',
                type: 'success'
            })
        } catch (e) {
            console.log(e)
            this.$message({
                message: 'Error',
                type: 'error'
            })
        }
        this.loading = false
    }

    async editBlog() {
        type KeysMatching<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];
        const trimFields: (KeysMatching<NewBlogPost, string>)[] = ["title", "meta_description", "url_title"]
        for (const f of trimFields) {
            this.newBlog[f] = this.newBlog[f].trim()
        }

        const body = BlogPostAdminEdit.createUpdateObj(this.newBlog, this.blog as any as BlogPostAdminEdit)
        if (body.publish_on === 0)
            delete body.publish_on
        delete body.thumbnailURL
        delete body.thumbnailSquareURL
        // if (this.newBlog.meta_title !== this.blog.meta_title)
        //     update['meta_title'] = this.newBlog.meta_title.trim()

        this.loading = true
        try {
            await createRequest(`/blog/blog-post/${this.$route.params['id']}`, 'PATCH', {}, {
                ...body,
                update: this.newBlog.update
            }).send()
            if (this.newBlog.new_thumbnail || this.newBlog.new_thumbnail_square)
                await this.uploadThumbnails({blogId: this.$route.params['id'], thumbnail: this.newBlog.new_thumbnail, thumbnailSq: this.newBlog.new_thumbnail_square})
            await this.getBlog()
            this.$message({
                message: 'Success, Blog saved.',
                type: 'success'
            })
        } catch (e) {
            console.log(e)
            this.$message({
                message: 'Error',
                type: 'error'
            })
        }
        this.loading = false
    }

    exportBlog() {
        const bCopy: NewBlogPost = JSON.parse(JSON.stringify(this.newBlog))

        delete bCopy.images
        delete bCopy.member_id
        delete bCopy.display_name
        delete bCopy.released
        delete bCopy.thumbnail
        delete bCopy.thumbnail_square
        delete bCopy.new_thumbnail
        delete bCopy.new_thumbnail_square
        delete bCopy.thumbnailURL_large
        delete bCopy.thumbnailSquareURL_large
        delete bCopy.meta_title
        delete bCopy.update

        copy(JSON.stringify(bCopy), {
            debug: true,
            message: 'Press #{key} to copy',
        })

        this.$message.success('Copied')
    }

    importBlog(e) {
        navigator.clipboard.readText()
            .then(async text => {
                try {
                    const importObj = JSON.parse(text)
                    const importNewBlog = new NewBlogPost()
                    Object.assign(importNewBlog, importObj)
                    importNewBlog.thumbnailURL = await this.toDataUrl(importNewBlog.thumbnailURL) as string
                    importNewBlog.thumbnailSquareURL = await this.toDataUrl(importNewBlog.thumbnailSquareURL) as string

                    Object.assign(this.newBlog, importNewBlog)

                    this.newBlog.new_thumbnail = this.dataURLtoFile(this.newBlog.thumbnailURL, 'thumbnail.jpg')
                    this.newBlog.new_thumbnail_square = this.dataURLtoFile(this.newBlog.thumbnailSquareURL, 'thumbnailSquare.jpg')

                    this.$message.success('Imported')
                } catch (err) {
                    this.$message.error('Failed to parse data')
                    console.error('Failed to parse data', err)
                }
            })
            .catch(err => {
                this.$message.error('Failed to read clipboard')
                console.error('Failed to read clipboard', err)
            })
    }

    toDataUrl(url) {
        return new Promise((resolve, reject) => {
            try {
                const xhr = new XMLHttpRequest()

                xhr.responseType = 'arraybuffer'
                xhr.open('GET', url)

                xhr.onload = function () {
                    let base64, binary, bytes, mediaType

                    bytes = new Uint8Array(xhr.response)
                    //NOTE String.fromCharCode.apply(String, ...
                    //may cause "Maximum call stack size exceeded"
                    binary = [].map.call(bytes, function (byte) {
                        return String.fromCharCode(byte)
                    }).join('')
                    mediaType = xhr.getResponseHeader('content-type')
                    base64 = [
                        'data:',
                        mediaType ? mediaType + ';' : '',
                        'base64,',
                        btoa(binary)
                    ].join('')
                    resolve(base64)
                }
                xhr.onerror = (e) => {
                    reject(e)
                }

                xhr.send()
            } catch (e) {
                console.log(e)
            }

        })
    }

    dataURLtoFile(dataurl: string, filename: string): File | void {
        const arr = dataurl.split(',')
        const matches = arr[0].match(/:(.*?);/)
        if (matches) {
            const mime = matches[1]
            const bstr = atob(arr[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], filename, {type: mime})
        } else return
    }
}

