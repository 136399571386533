import {Course} from '~/components/data-class/data-class'
import {courseLink, faqTextToData} from '~/utils/misc'
import {createRequest} from '~/utils/network-request'
import {toInstanceForce} from '~/utils/Serializer'

const WEB_DOMAIN = location.href.startsWith('https://admin.afterschool.com') ? 'https://afterschool.com.hk' : 'https://demo.afterschool.com.hk'

export default async function generateHTMLFromBlocks(blocks: any[]) {
    //  if empty return empty string
    if (!blocks || !blocks.length) {
        return ''
    }

    //  create container DIV
    const container = document.createElement('DIV')

    //  insert style sheet
    const styleSheets: string[] = [
        '/assets/css/vendors.bundle.css',
        '/assets/css/style.css',
        '/assets/css/blog.css',
    ]
    styleSheets.map(item => {
        container.appendChild(generateStyleSheetLink(item))
    })

    //  create blog content wrapper
    const blogContent = document.createElement('DIV')
    blogContent.classList.add('blog-content')

    //  convert blocks to html and append to blog content
    for (const item of blocks) {
        if (item.type === 'tableOfContent') {
            continue
        }

        const block = document.createElement('DIV')
        block.classList.add('bk', `bk-${item.type}`)

        block.innerHTML = await transformContent(item, blocks)

        blogContent.appendChild(block)
    }

    container.appendChild(blogContent)

    //  prepare for export HTML element to string
    const extraWrap = document.createElement('DIV')
    extraWrap.appendChild(container)

    return extraWrap.innerHTML
}

function generateStyleSheetLink(styleSheet) {
    const link = document.createElement('LINK')
    link['rel'] = 'stylesheet'
    link['href'] = WEB_DOMAIN + styleSheet
    return link
}

async function transformContent(item, blocks) {
    switch (item.type) {
        case 'header':
            return transformHeader(item.data)
        case 'paragraph':
            return item.data.text
        case 'list':
            return transformList(item.data.style, item.data.items)
        case 'quote':
            return transformQuote(item.data)
        case 'embed':
            return transformEmbed(item.data)
        case 'image':
            return transformImage(item.data)
        case 'tableOfContent':
            return ''
        case 'raw':
            return transformRaw(item.data)
        case 'table':
            return transformTable(item.data)
        case 'buttonLink':
            return transformButtonLink(item.data)
        case 'course':
            return await transformCourse(item.data)
        case 'faq':
            return await transformFaq(item.data)
        default:
            return ''
    }
}

function transformHeader(data) {
    const tag = 'h' + data.level
    if ([2, 3].includes(data.level)) {
        const id = getInnerTextFromHTML(data.text).replace(/\s/g, '_')
        return `<a class="anchor" id="${id}"></a><${tag} class="h${data.level + 2}">${data.text}</${tag}>`
    } else {
        return `<${tag}>${data.text}</${tag}>`
    }
}

function transformList(style, items) {
    const tag = style === 'ordered' ? 'ol' : 'ul'
    let html = items.reduce((html, item) => {
        return html + (typeof item === 'string' ? `<li>${item}</li>` : transformList(style, item))
    }, '')
    return `<${tag}>${html}</${tag}>`
}

function transformQuote(data) {
    //  {'text': '教育的崩潰足以摧毀一個國家', 'caption': '曼德拉', 'alignment': 'left'}

    const caption = data.caption === '' ? '' : ` <div class="font-size-18 font-weight-normal mt-4">${data.caption}</div>`

    return `
        <blockquote class="${data.alignment === 'center' ? 'text-center' : ''}">
            <div class="h5">${data.text}</div>
            ${caption}
        </blockquote>
    `
}

function transformEmbed(data) {
    const caption = data.caption === '' ? '' : ` <div class="p1 text-grey-700 mt-1">${data.caption}</div>`
    switch (data.service) {
        case 'youtube':
            return `
                <div class="mb-4">
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" src="${data.embed}" allowfullscreen></iframe>
                    </div>
                    ${caption}
                </div>

            `
    }
}

function transformImage(data) {
    /*
    {'type': 'image','data': {
    'file': {'url': 'https://lh3.googleusercontent.com/gm-FjX9jg04V4lrpW2li9Js0abOuKuSFryHwj5H6pZLIndmqcMom38BpSCaHWwRfo0FO02u-YZxLzmvP-7YB2AJ3BWDgWJSfyA=s0','fileName': 'sANo3G.png'}, 'caption': '', 'withBorder': false, 'stretched': false, 'withBackground': false
    }}
    */
    const caption = data.caption === '' ? '' : ` <div class="p1 text-grey-700 mt-1">${data.caption}</div>`
    const className: string[] = []
    if (data.stretched) className.push('w-100')
    if (data.withBackground) className.push('bk-image-with-bkg')
    return `
        <div class="mb-4">
            <img src="${data.file.url}" class="${className.join(' ')}"/>
            ${caption}
        </div>
    `
}

function transformTableOfContent(blocks) {
    const allHeader: any[] = blocks.filter(item => item['type'] === 'header' && [2, 3].includes(item.data.level))
    const headerList: any[] = []
    for (const item of allHeader) {
        if (item.data.level === 2) {
            headerList.push(item)
        } else {
            if (Object.prototype.toString.call(headerList[headerList.length - 1]) === '[object Array]') {
                headerList[headerList.length - 1].push(item)
            } else {
                headerList.push([item])
            }
        }
    }

    const arrayToLi = function (list) {
        let html = list.reduce((html, item) => {
            const isArray = Object.prototype.toString.call(item) === '[object Array]'

            let content = ''
            if (isArray) {
                content = arrayToLi(item)
            } else {
                const text = getInnerTextFromHTML(item.data.text)
                content = `<li><a href="#${text.replace(/\\s/g, '_')}">${text}</a></li>`
            }
            return html + content
        }, '')
        return `<ul>${html}</ul>`
    }

    return `
        <div class="p1 mb-2">目錄</div>
        ${arrayToLi(headerList)}
    `
}

function transformRaw(data) {
    //  {'type': 'raw', 'data': {'html': '...'}}
    return `
        <div class="w-100 overflow-hidden">
           ${data.html}
        </div>
    `
}

function transformTable(data) {
    let tableClass: string[] = ['blog-table']
    return `
        <div class="${tableClass.join(' ')}">
            ${data.html}
        </div>
    `
}

function transformButtonLink(data) {
    return `
        <a href="${data.url}" target="_blank" class="btn btn-${data.outline ? 'outline-' : ''}${data.color} btn-block btn-xl ">${data.text}</a>
    `
}

async function transformCourse(data) {
    if (data.id) {
        const course = await createRequest(`/course/${data.id}`, 'get').send(false)
            .then((res) => {
                return toInstanceForce(new Course(), res.data)
            }).catch(e => {
                console.log(e)
                return new Course()
            })


        if (!!course._id) {
            let coursePrice = ''
            if (course.price === 0) {
                coursePrice = `<span class="h3 text-primary">免費</span>`
            } else {
                coursePrice = `<span class="h5 h3-md text-primary">$${course.price}</span>`
            }

            return `
                <a href="${courseLink(course)}" target="_blank"> 
                    <div class="card">
                        <div class="course-image">
                            <img src="${imageUrl(course.thumbnailSquareURL, '-w200')}" class="position-absolute">
                        </div>
                        <div class="course-content">
                            <div class="s1 h6-md  mb-1 mb-md-2" style="max-height: 1.5rem; overflow: hidden;">${course.title}</div>
                            <div class="p2 p1-md text-grey-700">
                                ${course.display_name}
                            </div>
                            <div class="d-flex align-items-baseline justify-content-end mt-auto">
                                <span class="h6 mr-2" v-if="course.price!==course.original_price && course.original_price!== 0">
                                    <span>${Math.floor((1 - course.price / course.original_price) * 100)}%off</span>
                                </span>
                                <s class=" h6 mr-2">$${course.original_price}</s>
                                ${coursePrice}
                            </div>
                        </div>
                    </div>
                </a>
            `
        }
    } else {
        return ''
    }
}

function transformFaq(data) {
    return faqTextToData(data.text).reduce((html, item) => {
        return html + `
                    <div class="bk-faq-item">
                        <div class="mb-2">${item.q}</div>
                        <div>${item.a}</div>
                    </div>
                `
    }, '')
}

function getInnerTextFromHTML(html) {
    //  strip all html tag from data.text
    return html.replace(/<[^>]+>/g, '')
}

function imageUrl(url, options = '') {
    return url ? url + options + '-rj' : null
}
