













import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'

@Component({})
export default class ImageCropper extends Vue {
    @Prop({
        default: () => {
            return ''
        }
    })
    value!: string

    outWidthS: number = 320
    outHeightS: number = 180

    context: CanvasRenderingContext2D
    canvas: HTMLCanvasElement

    rawImage: CanvasImageSource // 1280

    WIDTH: number = 640
    HEIGHT: number = 360
    x: number = 0
    y: number = 0
    size: number = 100

    sizeStr = '100'

    mouseStartX: number = 0
    mouseStartY: number = 0

    dragok: boolean = false

    mounted() {
        this.canvas = this.$refs['myCanvas'] as HTMLCanvasElement
        this.context = this.canvas.getContext('2d') as CanvasRenderingContext2D
        this.draw()
    }

    draw() {
        this.context.fillStyle = 'white'
        this.context.fillRect(0, 0, this.WIDTH, this.HEIGHT)

        if (this.rawImage) {
            this.context.fillStyle = ''
            this.context.drawImage(this.rawImage, this.xOnCanvas() - this.imgWidth() / 2,
                this.yOnCanvas() - this.imgHeight() / 2, this.imgWidth(), this.imgHeight())
        }

        this.context.fillStyle = 'rgba(155, 187, 89, 0.7)'
        this.context.fillRect(0, 0, this.WIDTH, this.HEIGHT / 4)
        this.context.fillRect(0, 3 * this.HEIGHT / 4, this.WIDTH, this.HEIGHT / 4)
        this.context.fillRect(0, this.HEIGHT / 4, this.WIDTH / 4, this.HEIGHT / 2)
        this.context.fillRect(3 * this.WIDTH / 4, this.HEIGHT / 4, this.WIDTH / 4, this.HEIGHT / 2)
    }

    loadImage(path: string) {
        this.rawImage = new Image()
        this.rawImage.onload = () => {
            this.x = 0
            this.y = 0
            this.size = 1
            this.draw()
        }
        this.rawImage.crossOrigin = 'Anonymous'
        this.rawImage.src = path
    }

    setOutputSize(ws: number, hs: number) {
        this.outWidthS = ws
        this.outHeightS = hs
        this.WIDTH = ws * 2
        this.HEIGHT = hs * 2
        Vue.nextTick(() => {
            this.draw()
        })
    }

    crop(): string {
        // small
        // const smallCanvas = this.$refs['outputCanvasLarge'] as HTMLCanvasElement
        // const smallContext = smallCanvas.getContext('2d') as CanvasRenderingContext2D
        // smallCanvas.width = this.outWidthS
        // smallCanvas.height = this.outHeightS
        //
        // const xs = (this.outWidthS / 2) - (this.rawImage.width as number * this.size - this.x)
        // const ys = (this.outHeightS / 2) - (this.rawImage.height as number * this.size - this.y)
        //
        // smallContext.fillStyle = 'white'
        // smallContext.fillRect(0, 0, smallCanvas.width, smallCanvas.height)
        // smallContext.drawImage(this.rawImage, xs, ys,
        //     this.rawImage.width as number * this.size * 2, this.rawImage.height as number * this.size * 2)

        // big
        const bigCanvas = this.$refs['outputCanvasLarge'] as HTMLCanvasElement
        const bigContext = bigCanvas.getContext('2d') as CanvasRenderingContext2D
        bigCanvas.width = this.outWidthS * 2
        bigCanvas.height = this.outHeightS * 2

        const xl = (this.outWidthS * 2 / 2) - (this.rawImage.width as number * this.size - this.x * 2)
        const yl = (this.outHeightS * 2 / 2) - (this.rawImage.height as number * this.size - this.y * 2)

        bigContext.fillStyle = 'white'
        bigContext.fillRect(0, 0, bigCanvas.width, bigCanvas.height)
        bigContext.drawImage(this.rawImage, xl, yl,
            this.rawImage.width as number * this.size * 2, this.rawImage.height as number * this.size * 2)

        // return [smallCanvas.toDataURL('image/jpeg'), bigCanvas.toDataURL('image/jpeg')]
        // return smallCanvas.toDataURL('image/jpeg')
        return bigCanvas.toDataURL('image/jpeg')
    }

    @Emit()
    input(value: string) {
        //
    }

    get localValue(): string {
        return this.value
    }

    set localValue(value: string) {
        this.input(value)
    }

    @Watch('sizeStr')
    updateSize() {
        this.size = Number(this.sizeStr) / 100
        this.draw()
    }

    @Watch('value')
    startCropping() {
        this.loadImage(this.value)
    }

    xOnCanvas(): number {
        return this.WIDTH / 2 + this.x
    }

    yOnCanvas(): number {
        return this.HEIGHT / 2 + this.y
    }

    imgWidth(): number {
        return this.rawImage.width as number * this.size
    }

    imgHeight(): number {
        return this.rawImage.height as number * this.size
    }

    myDown(e) {
        const rect = this.canvas.getBoundingClientRect()
        if (e.clientX < this.xOnCanvas() + (this.imgWidth() / 2) + rect.left &&
            e.clientX > this.xOnCanvas() - (this.imgWidth() / 2) + rect.left &&
            e.clientY < this.yOnCanvas() + (this.imgHeight() / 2) + rect.top &&
            e.clientY > this.yOnCanvas() - (this.imgHeight() / 2) + rect.top) {
            this.mouseStartX = e.clientX - rect.left
            this.mouseStartY = e.clientY - rect.top
            this.dragok = true
            this.canvas.onmousemove = this.myMove
        }
    }

    myMove(e) {
        if (this.dragok) {
            const rect = this.canvas.getBoundingClientRect()
            const newX = e.clientX - rect.left
            const newY = e.clientY - rect.top

            this.x += newX - this.mouseStartX
            this.y += newY - this.mouseStartY

            this.mouseStartX = newX
            this.mouseStartY = newY

            this.draw()
        }
    }

    myUp() {
        this.dragok = false
        this.canvas.onmousemove = null
    }
}
