
















































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {BlogPost, NewBlogPost} from '~/components/data-class/data-class'
import UrlSlugInput from '~/components/url-slug-input.vue'
import {BlogTagList} from '@afterschool.dev/as-data-admin'

@Component({
    components: {UrlSlugInput}
})
export default class BlogInfo extends Vue {
    @Prop({
        default: () => {
            return new NewBlogPost()
        }
    })
    value!: NewBlogPost

    newTag: string = ''

    isDialogShown: boolean = false

    isWarningVisible: boolean = false

    urlTitleUsed: boolean = false

    urlTitleUsedDoc: BlogPost | null = null

    dropzoneUrl = process.env.VUE_APP_DOMAIN_API

    BlogTagList = BlogTagList

    dropzoneUploadCompleted(file, elem, response) {
        this.value.thumbnailURL = response['file_url']
    }

    dropzoneSquareUploadCompleted(file, elem, response) {
        this.value.thumbnailSquareURL = response['file_url']
    }

    created() {
        this.dropzoneUrl = `${process.env.VUE_APP_DOMAIN_API}/file`
    }

    get publish_on() {
        if (this.value.publish_on)
            return new Date(this.value.publish_on)
        else
            return null
    }

    set publish_on(value: Date | null) {
        this.value.publish_on = value ? value.getTime() : null
    }

    urlTitleInputOnFocus() {
        if (this.$route.params['id'] && !this.isDialogShown) {
            this.isDialogShown = true
            this.$alert('You are editing the url slug! Please make sure you know what you are doing.', 'CAUTION', {
                confirmButtonText: 'OK'
            })
        }
    }

    urlTitleInputOnChange() {
        if (this.$route.params['id']) {
            this.isWarningVisible = true
        }
    }

    @Emit()
    input(value: NewBlogPost) {
        //
    }

    get localValue(): NewBlogPost {
        return this.value
    }

    set localValue(value: NewBlogPost) {
        this.input(value)
    }
}

